<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-02-24 19:00:39
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-28 20:26:55
-->
<template>
  <div class="month_gmv">
    <a-table :data-source="tableData" bordered rowKey="id" :pagination="false" :columns="columns"></a-table>
  </div>
</template>

<script>
  import { MonthColumns } from './components/colums.js'
  import { MonthData } from './components/TableData.js'
  export default {
    data() {
      return {
        columns: MonthColumns,
        tableData: [],
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.axios.get('/api/order/order/monthlyPlannedTurnover/getMonthTurnover').then(res => {
          this.tableData = res.body
        })
      }
    }
  }
</script>

<style lang="scss">
  
</style>
